import {
  INTERNET_CONNECTION,
  NETWORK_ACCESSIBILITY,
  STATUS_CODES,
  TITAN_EU_SERVER_ACCESSIBILITY,
  TITAN_US_SERVER_ACCESSIBILITY,
  WEBSOCKET_EU_SERVER_ACCESSIBILITY,
  WEBSOCKET_US_SERVER_ACCESSIBILITY,
} from "../../constants";
import {
  sleep,
  checkConnection,
  checkWebsocketConnection,
} from "../../utils/checkConnectionUtils";
import {
  internetCheckUrls,
  titanEUServerCheckUrls,
  titanUSServerCheckUrls,
  websocketEUServerUrl,
  websocketUSServerUrl,
} from "../../services/urls";

const networkCheck = {
  name: NETWORK_ACCESSIBILITY,
  getPromise: async () => {
    await sleep(1000);
    return {
      status: window.navigator?.onLine
        ? STATUS_CODES.success
        : STATUS_CODES.badRequest,
    };
  },
};

const internetCheck = {
  name: INTERNET_CONNECTION,
  getPromise: async () => {
    await sleep(1000);
    return checkConnection(internetCheckUrls);
  },
};

const titanUSServerCheck = {
  name: TITAN_US_SERVER_ACCESSIBILITY,
  getPromise: async () => {
    await sleep(1000);
    return checkConnection(titanUSServerCheckUrls);
  },
};

const titanEUServerCheck = {
  name: TITAN_EU_SERVER_ACCESSIBILITY,
  getPromise: async () => {
    await sleep(1000);
    return await checkConnection(titanEUServerCheckUrls);
  },
};

const webSocketUSServerCheck = {
  name: WEBSOCKET_US_SERVER_ACCESSIBILITY,
  getPromise: async () => {
    await sleep(1000);
    return checkWebsocketConnection(websocketUSServerUrl);
  },
};

const webSocketEUServerCheck = {
  name: WEBSOCKET_EU_SERVER_ACCESSIBILITY,
  getPromise: async () => {
    await sleep(1000);
    return checkWebsocketConnection(websocketEUServerUrl);
  },
};

export const checksArray = [
  networkCheck,
  internetCheck,
  titanUSServerCheck,
  titanEUServerCheck,
  webSocketUSServerCheck,
  webSocketEUServerCheck,
];
