import { Input, Select } from "antd";
import { CLIENT_TYPE_OPTIONS } from "../../constants";
import s from "./TroubleShootFrom.module.less";

const TroubleShootForm = (props) => {
  const {
    email,
    handleEmailInput,
    validationError,
    handleEmailInputBlur,
    clientName,
    handleSelectClient,
    otherClientName,
    handleOtherClientName,
  } = props;

  return (
    <div className={s.userForm}>
      <h3 className={s.emailInputTitle}>Titan Email</h3>
      <p className={s.emailInputSubTitle}>Please enter titan email</p>
      <Input
        placeholder="Enter your Titan email"
        value={email}
        onChange={handleEmailInput}
        className={s.emailInput}
        status={validationError?.email ? "error" : ""}
        onBlur={handleEmailInputBlur}
      />
      {validationError?.email && (
        <p className={s.emailInputError}>{validationError?.email}</p>
      )}
      <h3 className={s.selectClientTitle}>Mail client type</h3>
      <Select
        options={CLIENT_TYPE_OPTIONS}
        placeholder="Select your mail client type"
        className={s.selectClient}
        value={clientName}
        onChange={handleSelectClient}
      />
      {clientName === "other" && (
        <>
          <h4 className={s.otherClientTitle}>Other Client Name</h4>
          <Input
            placeholder="Enter other mail client name"
            value={otherClientName}
            onChange={handleOtherClientName}
            className={s.emailInput}
          />
        </>
      )}
    </div>
  );
};

export default TroubleShootForm;
