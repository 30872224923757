import { Button } from "antd";
import CopyResult from "../CopyResult";

const ModalFooter = ({ result, submitReport, isSubmitting, onModalClose }) => {
  const sendReport = async () => {
    await submitReport();
    onModalClose();
  };

  return (
    <div>
      <CopyResult result={result} />
      <Button onClick={sendReport} type="primary" loading={isSubmitting}>
        Submit report
      </Button>
    </div>
  );
};

export default ModalFooter;
