import { notification } from "antd";

const DEFAULT_TOAST_DURATION = 2.5;

export const showToast = ({ message, duration, ...restProps }) => {
  notification.config({ maxCount: 1 });

  return notification.open({
    message,
    duration: duration || DEFAULT_TOAST_DURATION,
    placement: "bottom",
    ...restProps,
  });
};
