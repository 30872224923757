import React from "react";
import { Button } from "antd";
import { CheckmarkSVG, WarningSVG, CheckedSVG } from "../../assets";
import CopyResult from "../CopyResult";
import { getDescription } from "../../utils/checkConnectionUtils";
import {
  isAllTestsPassed,
} from "../../utils/troubleShooterUtils";
import { isConnectionSuccessful } from "../../utils/commonUtils";
import s from "./TroubleShootResult.module.less";

function TroubleShootResult({
  result,
  checkAgain,
  handleOpenSubmitReportModal,
  isReportSubmitted,
}) {
  const retry = () => {
    checkAgain();
  };

  const allTestPassed = isAllTestsPassed(result);

  return (
    <div className={s.container}>
      <h2 className={s.title}>Diagnosis report</h2>
      <div className={s.reportCard}>
        <div className={s.reportHeader}>
          <span>{allTestPassed ? "No " : ""}Problems found</span>
          <Button onClick={retry}>Retry</Button>
        </div>
        <Reports result={result} />
        <div className={s.reportFooter}>
          <CheckedSVG className={s.checkedSVG} />
          Logs attached
        </div>
      </div>
      <div className={s.buttonWrapper}>
        <CopyResult result={result} className={s.copy} />
        {!isReportSubmitted.current && (
          <Button
            className={s.contactSupport}
            type="primary"
            onClick={handleOpenSubmitReportModal}
          >
            Submit report
          </Button>
        )}
      </div>
    </div>
  );
}

function Reports({ result }) {
  const tests = Object.keys(result);
  return (
    <div className={s.reports}>
      {tests.length !== 0 &&
        tests.map((testName) => {
          const Icon = isConnectionSuccessful(result[testName]?.status)
            ? CheckmarkSVG
            : WarningSVG;
          return (
            <div key={testName} className={s.problem}>
              <Icon width={16} height={16} className={s.icon} />
              {getDescription(testName, "success")}
            </div>
          );
        })}
    </div>
  );
}

export default TroubleShootResult;
