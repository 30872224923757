import { Tooltip, Button } from "antd";
import { showToast } from "../../utils/toastUtils";
import { TOAST_MSGS } from "../../constants/toastMsgs";

const CopyResult = ({ result, ...restProps }) => {
  const copyResult = () => {
    navigator.clipboard.writeText(JSON.stringify(result));
    showToast({ message: TOAST_MSGS.copied });
  };

  return (
    <Tooltip
      title="Copy JSON to contact support"
      destroyTooltipOnHide
      placement="left"
    >
      <Button onClick={copyResult} {...restProps}>
        Copy
      </Button>
    </Tooltip>
  );
};

export default CopyResult;
