import { STATUS_CODES, descriptions } from "../constants";

export const sleep = async (time) => {
  return new Promise((r) => setTimeout(r, time));
};

export const getUncachedUrl = (originalUrl) => {
  const url = new URL(originalUrl);
  url.searchParams.append("date", `${Date.now()}${Math.random()}`);
  return url.href;
};

export const checkWebsocketConnection = (websocketUrl) => {
  const ws = new WebSocket(websocketUrl);
  let wasOpened = false;

  const promise = new Promise((resolve) => {
    ws.onopen = () => {
      console.log("Websocket connected");
      wasOpened = true;
      resolve({ status: STATUS_CODES.success, failureError: null });
      ws.close(); // Close after connection is confirmed
    };

    ws.onerror = (err) => {
      console.log("Websocket connection error:", err);
      resolve({
        status: STATUS_CODES.badRequest,
        failureError: err.message || "Unknown error",
      });
    };

    ws.onclose = (event) => {
      console.log("Websocket closed");
      if (wasOpened && event.wasClean) {
        resolve({ status: STATUS_CODES.success, failureError: null });
      } else if (wasOpened) {
        resolve({
          status: STATUS_CODES.badRequest,
          failureError: `Connection closed unexpectedly: ${event.reason}`,
        });
      } else {
        resolve({
          status: STATUS_CODES.badRequest,
          failureError: "Connection failed before opening",
        });
      }
    };

    setTimeout(() => {
      if (!wasOpened) {
        resolve({
          status: STATUS_CODES.badRequest,
          failureError: "Connection timeout",
        });
        ws.close();
      }
    }, 10 * 1000);
  });

  return promise;
};

export const checkConnection = async (
  connectionCheckUrls,
  useOriginal = false
) => {
  try {
    const responses = await Promise.all(
      connectionCheckUrls.map((url) =>
        fetch(useOriginal ? url : getUncachedUrl(url))
      )
    );
    const failedResponseIndex = responses.findIndex((res) => !res.ok);

    if (failedResponseIndex === -1) {
      return {
        status: responses[responses.length - 1]?.status,
        failureError: null,
        checkedUrls: connectionCheckUrls,
      };
    }
    const failedResponse = responses[failedResponseIndex];

    return {
      status: failedResponse.status,
      failureError: await getFailureError(failedResponse),
      checkedUrls: connectionCheckUrls,
      failedUrl: connectionCheckUrls[failedResponseIndex],
    };
  } catch (error) {
    console.log("error in check connection, (most likely network or DNS)", error);
    return {
      status: STATUS_CODES.networkError,
      failureError: `network error (${error})`, 
      checkedUrls: connectionCheckUrls,
    };
  }
};

const getFailureError = async (res) => {
  try {
    if (!res) {
      return "No response";
    }
    const contentType = res.headers.get("Content-Type");
    if (contentType.includes("application/json")) {
      return res.json();
    } else if (contentType.includes("text")) {
      return res.text();
    }
    return null;
  } catch (error) {
    console.log("error while parsing failure error", error);
    return `error while parsing failure error (${error?.toString()})`;
  }
};

export const getDescription = (name, type) => {
  const des = descriptions?.[name]?.[type];
  return des || "";
};
