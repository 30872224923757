import React from "react";
import useTitanTroubleShooter from "./useTitanTroubleShooter";
import TroubleShootChecks from "../../components/TroubleShootChecks";
import TroubleShootResult from "../../components/TroubleShootResult";
import SubmitReportModal from "../../components/SubmitReportModal";
import s from "./TitanTroubleShooter.module.less";

const TitanTroubleShooter = () => {
  const {
    isTroubleShootingCompleted,
    onCompletion,
    checkAgain,
    result,
    email,
    clientName,
    otherClientName,
    setValidationError,
    handleEmailInput,
    validationError,
    handleEmailInputBlur,
    handleSelectClient,
    handleOtherClientName,
    isSubmitReportModalOpen,
    handleCloseSubmitReportModal,
    handleOpenSubmitReportModal,
    ip,
    isReportSubmitted,
  } = useTitanTroubleShooter();

  return (
    <div className={s.troubleShooter}>
      {!isTroubleShootingCompleted ? (
        <TroubleShootChecks onCompletion={onCompletion} />
      ) : (
        <TroubleShootResult
          checkAgain={checkAgain}
          result={result}
          handleOpenSubmitReportModal={handleOpenSubmitReportModal}
          isReportSubmitted={isReportSubmitted}
        />
      )}
      <SubmitReportModal
        open={isSubmitReportModalOpen}
        onModalClose={handleCloseSubmitReportModal}
        email={email}
        handleEmailInput={handleEmailInput}
        validationError={validationError}
        handleEmailInputBlur={handleEmailInputBlur}
        clientName={clientName}
        handleSelectClient={handleSelectClient}
        otherClientName={otherClientName}
        handleOtherClientName={handleOtherClientName}
        setValidationError={setValidationError}
        result={result}
        ip={ip}
        isReportSubmitted={isReportSubmitted}
      />
    </div>
  );
};

export default TitanTroubleShooter;
