import { useState } from "react";
import { checkValidation } from "../utils/fieldValidation";
import { showToast } from "../utils/toastUtils";
import { TOAST_MSGS } from "../constants/toastMsgs";
import { postTroubleShootReport } from "../services/titanTroubleShooterService";
import { getDiagnosticData } from "../utils/troubleShooterUtils";

const useSubmitReport = ({
  email,
  setValidationError,
  clientName,
  result,
  ip,
  isReportSubmitted,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitReport = async () => {
    try {
      const validationObj = checkValidation(email);
      setValidationError(validationObj);
      for (const [value] of Object.values(validationObj)) {
        if (value) {
          return;
        }
      }
      setIsSubmitting(true);
      const data = {
        email,
        clientName,
        ip: ip.current,
        userAgent: navigator?.userAgent,
        PROD: getDiagnosticData("US", result),
        PROD_EUC1: getDiagnosticData("EU", result),
      };
      await postTroubleShootReport(data);
      isReportSubmitted.current = true;
      showToast({ message: TOAST_MSGS.submitReport });
    } catch (e) {
      showToast({ message: TOAST_MSGS.apiError });
      throw new Error(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    submitReport,
  };
};

export default useSubmitReport;
