import { Modal } from "antd";
import ModalFooter from "./ModalFooter";
import TroubleShootForm from "../TroubleShootForm";
import useSubmitReport from "../../hooks/useSubmitReport";
import s from "./SubmitReportModal.module.less";

const SubmitReportModal = (props) => {
  const {
    open,
    onModalClose,
    setValidationError,
    result,
    ip,
    isReportSubmitted,
    ...restProps
  } = props;
  const { email, clientName } = restProps;

  const { isSubmitting, submitReport } = useSubmitReport({
    email,
    setValidationError,
    clientName,
    result,
    ip,
    isReportSubmitted,
  });

  return (
    <Modal
      open={open}
      title="Submit report to Titan Support"
      className="submitReportModal"
      okText="Submit report"
      destroyOnClose
      onCancel={onModalClose}
      confirmLoading={isSubmitting}
      maskClosable={false}
      footer={
        <ModalFooter
          result={result}
          submitReport={submitReport}
          isSubmitting={isSubmitting}
          onModalClose={onModalClose}
        />
      }
    >
      <TroubleShootForm {...restProps} />
    </Modal>
  );
};

export default SubmitReportModal;
