export const STATUS_CODES = {
  success: 200,
  badRequest: 400,
  networkError: 0,
};

export const NETWORK_ACCESSIBILITY = "networkAccessibility";
export const INTERNET_CONNECTION = "internetConnection";
export const TITAN_US_SERVER_ACCESSIBILITY = "titanUSServerAccessibility";
export const TITAN_EU_SERVER_ACCESSIBILITY = "titanEUServerAccessibility";
export const WEBSOCKET_US_SERVER_ACCESSIBILITY =
  "websocketUSServerAccessibility";
export const WEBSOCKET_EU_SERVER_ACCESSIBILITY =
  "websocketEUServerAccessibility";

export const descriptions = {
  [NETWORK_ACCESSIBILITY]: {
    running: "Checking machine’s network access...",
    success: "Network accessibility on your machine",
    failed: "Network accessibility on your machine failed",
  },
  [INTERNET_CONNECTION]: {
    running: "Checking internet connection...",
    success: "Internet connection ",
    failed: "Internet connection failed",
  },
  [TITAN_US_SERVER_ACCESSIBILITY]: {
    running: "Checking Titan server(US)...",
    success: "Titan server(US) connection",
    failed: "Titan server(US) connection failed",
  },
  [TITAN_EU_SERVER_ACCESSIBILITY]: {
    running: "Checking Titan server(EU)...",
    success: "Titan server(EU) connection",
    failed: "Titan server(EU) connection failed",
  },
  [WEBSOCKET_US_SERVER_ACCESSIBILITY]: {
    running: "Checking Websocket server(US)...",
    success: "Websocket server(US) connection",
    failed: "Websocket server(US) connection failed",
  },
  [WEBSOCKET_EU_SERVER_ACCESSIBILITY]: {
    running: "Checking Websocket server(EU)...",
    success: "Websocket server(EU) connection",
    failed: "Websocket server(EU) connection failed",
  },
};

export const CLIENT_TYPE_OPTIONS = [
  { value: "titan", label: "Titan" },
  { value: "outlook", label: "Outlook" },
  { value: "thunderbird", label: "Thunderbird" },
  { value: "other", label: "Other" },
];
