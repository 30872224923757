import {
  INTERNET_CONNECTION,
  NETWORK_ACCESSIBILITY,
  TITAN_EU_SERVER_ACCESSIBILITY,
  TITAN_US_SERVER_ACCESSIBILITY,
  WEBSOCKET_EU_SERVER_ACCESSIBILITY,
  WEBSOCKET_US_SERVER_ACCESSIBILITY,
} from "../constants";
import { isConnectionSuccessful } from "./commonUtils";

export const isAllTestsPassed = (result) => {
  for (const [, value] of Object.entries(result || {})) {
    if (!isConnectionSuccessful(value?.status)) {
      return false;
    }
  }
  return true;
};

const getConnectionStatus = (status) => {
  return isConnectionSuccessful(status) ? "success" : "failure";
};

const getFormattedData = (result, key) => {
  const data = result[key];
  return {
    status: getConnectionStatus(data?.status),
    failureError: data?.failureError,
    ...(data?.checkedUrls ? { checkedUrls: data.checkedUrls } : {}),
    ...(data?.failedUrl ? { failedUrl: data.failedUrl } : {}),
  };
};

// type="EU"|"US"
export const getDiagnosticData = (type, result) => {
  const mailServerAccessibility =
    type === "US"
      ? TITAN_US_SERVER_ACCESSIBILITY
      : TITAN_EU_SERVER_ACCESSIBILITY;

  const websocketAccessibility =
    type === "US"
      ? WEBSOCKET_US_SERVER_ACCESSIBILITY
      : WEBSOCKET_EU_SERVER_ACCESSIBILITY;

  return {
    networkAccessibility: getFormattedData(result, NETWORK_ACCESSIBILITY),
    internetConnection: getFormattedData(result, INTERNET_CONNECTION),
    mailServerAccessibility: getFormattedData(result, mailServerAccessibility),
    websocketAccessibility: getFormattedData(result, websocketAccessibility),
  };
};
