import { useCallback, useEffect, useRef, useState } from "react";
import { emailValidation } from "../../utils/fieldValidation";
import { fetchIPAddress } from "../../services/titanTroubleShooterService";

const useTitanTroubleShooter = () => {
  const [isTroubleShootingCompleted, setIsTroubleShootingCompleted] =
    useState(false);

  const [result, setResult] = useState({});
  const [email, setEmail] = useState("");
  const [clientName, setClientName] = useState("titan");
  const [otherClientName, setOtherClientName] = useState("");
  const [validationError, setValidationError] = useState({});
  const [isSubmitReportModalOpen, setIsubmitReportModalOpen] = useState(false);
  const ip = useRef("");
  const isReportSubmitted = useRef(false);

  const onCompletion = useCallback((result) => {
    setIsTroubleShootingCompleted(true);
    setResult(result);
    handleOpenSubmitReportModal();
  }, []);

  const checkAgain = useCallback(() => {
    setIsTroubleShootingCompleted(false);
    setResult({});
    isReportSubmitted.current = false;
  }, []);

  const handleEmailInput = (e) => {
    setEmail(e?.target?.value);
    setValidationError({
      ...validationError,
      email: null,
    });
  };

  const handleEmailInputBlur = (e) => {
    setValidationError({
      ...validationError,
      email: emailValidation(email),
    });
  };

  const handleSelectClient = (value) => {
    setClientName(value);
  };

  const handleOtherClientName = (e) => {
    setOtherClientName(e?.target?.value);
  };

  const handleOpenSubmitReportModal = () => {
    setIsubmitReportModalOpen(true);
  };

  const handleCloseSubmitReportModal = () => {
    setIsubmitReportModalOpen(false);
  };

  const fetchIp = async () => {
    ip.current = await fetchIPAddress();
  };

  useEffect(() => {
    fetchIp();
  }, []);

  return {
    isTroubleShootingCompleted,
    result,
    clientName,
    otherClientName,
    email,
    validationError,
    onCompletion,
    checkAgain,
    handleEmailInput,
    handleEmailInputBlur,
    handleOtherClientName,
    handleSelectClient,
    setValidationError,
    isSubmitReportModalOpen,
    handleCloseSubmitReportModal,
    handleOpenSubmitReportModal,
    ip,
    isReportSubmitted,
  };
};

export default useTitanTroubleShooter;
