import React from "react";
import { checksArray } from "./Checks";
import { sleep, getDescription } from "../../utils/checkConnectionUtils";
import s from "./TroubleShootChecks.module.less";

class TroubleShootChecks extends React.PureComponent {
  state = {
    diagnosticData: {},
  };

  componentDidMount() {
    this.startChecking(0);
  }

  startChecking = async (currentCheckIndex) => {
    if (currentCheckIndex >= checksArray.length) {
      this.props.onCompletion?.(this.state.diagnosticData);
      return;
    }
    const { name, getPromise } = checksArray[currentCheckIndex];
    const data = await getPromise();
    this.setState((state) => {
      return {
        diagnosticData: {
          ...state.diagnosticData,
          [name]: data,
        },
      };
    });
    await sleep(0);
    this.startChecking(currentCheckIndex + 1);
  };

  render() {
    const { diagnosticData } = this.state;
    const tests = Object.keys(diagnosticData);
    const name = tests[tests.length - 1];
    const description = getDescription(name, "running");
    const percentage = (tests?.length / checksArray.length) * 100;

    return (
      <div className={s.container}>
        <h2 className={s.title}>Running diagnosis</h2>
        <div className={s.description}>{description}</div>
        <ProgressBar percentage={percentage} />
      </div>
    );
  }
}

function ProgressBar({ percentage }) {
  return (
    <div className={s.progressBarContainer}>
      <div className={s.progress} style={{ width: `${percentage}%` }} />
    </div>
  );
}

export default TroubleShootChecks;
