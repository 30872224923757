export const emailValidation = (email) => {
  if (!email) {
    return "Please enter email address to contact support";
  }
  const emailRegex = /[^@]+@[^.]+\..+/g;
  if (!emailRegex.test(email.trim().toLowerCase())) {
    return "Please enter valid email";
  }
  return null;
};

export const checkValidation = (email) => {
  let validationObj = {};
  const emailValidationError = emailValidation(email);
  if (emailValidationError) {
    validationObj = { ...validationObj, email: emailValidationError };
  }
  return validationObj;
};
